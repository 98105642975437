import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import SipkaButton from "../assets/img/sipkaButton.png";
import styled from "styled-components";
import Breadscrum from "../components/Breadscrum";

const NotFound404Inner = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 100px;
  width: calc(100% - 50px);
  max-width: 500px;

  @media (min-width: 900px) {
    max-width: 900px;
    margin-top: 100px;
    height: calc(100vh - 400px);
    min-height: calc(400px);
    margin-bottom: 0;
  }

  h1 {
    font-family: Ivar;
    font-size: 39px;
    color: #000000;
  }

  p {
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #000000;
    line-height: 25px;
    margin-top: 30px;
  }

  a {
    font-family: Visuelt-Medium;
    font-size: 18px;
    color: #fff;
    text-align: right;
    background: #243A58;
    border-radius: 5px;
    padding: 20px 30px;
    display: block;
    width: fit-content;
    margin-top: 50px;
    text-decoration: none;

    img {
      height: 11px;
      margin-left: 10px;
      filter: invert();
    }
  }
`;



const NotFound404 = () => {
  const browser = typeof window !== "undefined" && window;
  
  if (!browser) {
    console.log("browser fail");
  }

  return (
    browser && (
      <Layout>
        <SEO
          title="Stránka nenalezena"
        />

        <Breadscrum
          visible={false}
          stranky={[
            {name: "Domů", url: "/"},
            {name: "404", url: "/404"},
          ]}
        />

        <NotFound404Inner>
          <h1>404 - stránka nenalezena</h1>
          <p>Bohužel, stránka, kterou hledáte neexistuje. Zkuste se vrátit zpět nebo přejděte na úvodní stránku webu.</p>
          <Link to="/">Úvodní stránka<img src={SipkaButton} alt="sipka"/></Link>
        </NotFound404Inner>
      </Layout>
    )
  );
}

export default NotFound404;
